import React, { Component } from 'react';
import Responsive from '../libraries/responsive';
import route from '../libraries/route';
import { Helmet } from 'react-helmet';
import Slideshow from '../components/Slideshow';
import TopMenu from '../components/TopMenu';
import BottomMenu from '../components/BottomMenu';
import images from "../assets/images"
import { Link } from 'gatsby';

class Product extends Component {
    render() {
        const { product } = this.props.pageContext;
        const { metaTitle, metaDescription } = product
        return (
          <>
            <TopMenu />
            <div className="product">
              <Helmet title={metaTitle}>
                <meta name="description" content={metaDescription} />
              </Helmet>
              <Responsive device="desktop">
                <main className="product-desktop">
                  <section className="product-presentation">
                    <Slideshow
                      showIndex
                      showArrows
                      autoplay
                      enableKeyboard
                      useDotIndex
                      slideInterval={5000}
                      defaultIndex={0}
                      slides={product.images.map((productImage, index) => ({
                        title: `${productImage.replace(/[0-9]/g, "")} ${index +
                          1}`,
                        url: images[productImage],
                      }))}
                      effect={"fade"}
                    />
                    <div>
                      <div>
                        <h4>{product.title}</h4>
                        <Link
                          className="white-link-button"
                          to={route("contacts")}
                        >
                          {"Contattaci"}
                        </Link>
                      </div>
                      <span>{product.description}</span>
                    </div>
                  </section>
                </main>
              </Responsive>
              <Responsive device="not-desktop">
                <main className="product-mobile">
                  <section className="product-presentation">
                    <Slideshow
                      showArrows
                      autoplay
                      enableKeyboard
                      slideInterval={5000}
                      defaultIndex={0}
                      slides={product.images.map((productImage, index) => ({
                        title: `${productImage.replace(/[0-9]/g, "")} ${index +
                          1}`,
                        url: images[productImage],
                      }))}
                      effect={"fade"}
                    />
                    <div>
                      <span>
                        <h4>{product.title}</h4>
                      </span>
                      <span>{product.description}</span>
                      <p>
                        <Link
                          className="white-link-button"
                          to={route("contacts")}
                        >
                          {"Contattaci"}
                        </Link>
                      </p>
                    </div>
                  </section>
                </main>
              </Responsive>
            </div>
            <BottomMenu />
          </>
        )
    }
}

export default Product;
